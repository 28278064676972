//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStrapiMedia } from "../utils/medias";
import { getMetaTags } from "../utils/seo";
import ProductCard from "../components/products/ProductCard";
import axios from "axios";

export default {
  fetchOnServer: false,
  data() {
    return {
      latestProducts: [],
      years: [],
      latestitems: [],
      loagingStatus: true,
      home: {
        imageSlider: {},
        testimonials: [],
        seo: { metaTitle: "", shareImage: {} },
      },
      products: [],
      allprod: [],
      featuredProducts: [],
      newArrivals:[],
      filterProducts: [],
      categories: [],
      brands: [],
      models: [],
      brandmodels: [],
      active_el: "",

      page: 1,
      loadbtn: 0,
      productVisible: 6,
      step: 3,
      pageSize: 10,
      showCollapse: true,
      catSearchId: "",
      catfilterId: 0,
      brandSearchId: "",
      modelSearchId: "",
      watermarkUrl:
        "https://res.cloudinary.com/forexmotors-com/image/upload/l_Forex-vehicles:watermark/",
      specialvehiclesetting: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1320,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      yearSearchId: "",
      searchResult: [],
      settings: {
        dots: false,
        infinite: false,
        speed: 500,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: false,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
              dots: false,
            },
          },
        ],
      },

      setting: {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              speed: 2000,
              autoplayspeed: 2000,
              Autoplay: true,
              dots: false,
              arrows: true,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
              arrows: true,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
            },
          },
        ],
      },

      faq: {
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: false,
        adaptiveHeight: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1367,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              speed: 2000,
              autoplayspeed: 2000,
              Autoplay: true,
              dots: false,
              arrows: false,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: false,
              arrows: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              dots: false,
            },
          },
        ],
      },
    };
  },

  components: {
    ProductCard,
  },

  async asyncData({ $strapi, params }) {
    const home = await $strapi.find("home");
    return { home };
  },
  
  //Fetching Datas
  async fetch() {
    // const resp = await axios.get("http://localhost:3004/products")
    axios
      .get("https://api.forexmotors.com/products")
      .then((resp) => {
        this.loagingStatus = false;

        const latestProducts = resp.data
          .map((item) => item)
          .sort((a, b) => a.sortOrder - b.sortOrder);

        this.products = latestProducts;

        this.allprod = JSON.parse(JSON.stringify(this.products));

        this.featuredProducts =
          this.allprod.filter((el) => el.specialOffer == true) || [];

        this.newArrivals =
          this.allprod.filter((el) => el.newArrival == true) || [];

        // let productVisible = parseInt(6);

        // this.filterProducts = latestProducts.slice(0, productVisible);
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });

    // this.$strapi.graphql({
    //     query: `query{
    //               categories {
    //                   id
    //                   name
    //                   SortOrder
    //                   categoryImage {
    //                     url
    //                   }
    //                   image {
    //                     url
    //                   }
    //                 }
    //             }`
    //   }).then((res) =>{

    //   this.categories = res.categories
    //   .map((item) => item)
    //   .sort((a, b) => a.SortOrder - b.SortOrder);

    //   });

    this.categories = this.$store.state.category.categories;

    this.$strapi
      .graphql({
        query: `query{
              brands {
                  id
                  name
                  SortOrder
                  image {
                    url
                    }
                }
              }`,
      })
      .then((res) => {
        this.brands = res.brands
          .map((item) => item)
          .sort((a, b) => a.SortOrder - b.SortOrder);
      });

    this.$strapi
      .graphql({
        query: `query{
              products(limit: 6){
                id,
                name,
                slug,
                model{
                    id
                    name
                }
                category{
                  name
                },
                 image{
                  formats
                },
                features{
                  makeYear
                }

              }
            }`,
      })

      //  this.$strapi.find('products',  {_limit: -1} )
      .then((res) => {
        this.loagingStatus = false
          const latestProducts = res.products.map((item) => item)
        .sort((a, b) => a.sortOrder - b.sortOrder);
          this.products = latestProducts
          this.allprod = JSON.parse(JSON.stringify(this.products));
        //   this.featuredProducts = this.allprod.filter((el) => el.specialOffer == true) || [];
        //   let productVisible = parseInt(6);
          this.filterProducts = latestProducts;
      });

    this.$strapi.find("global").then((res) => (this.years = res));

    this.$strapi.find("models").then((res) => {
      this.models = res
        .map((item) => item)
        .sort((a, b) => a.sortOrder - b.sortOrder);
      this.brandmodels = this.models;
    });
  },

  watch: {
    "$store.state.category.categories": function (data) {
      this.categories = data;
    },
  },
  methods: {
    getStrapiMedia,

    // Load More Function
    loadMore() {
      let catId = this.catSearchId;
      let brId = this.brandSearchId;
      let mdId = this.modelSearchId;
      let yrId = this.yearSearchId;
      let allProducts = this.allprod;
      let catSearch = this.catfilterId;

      if (catSearch !== 0) {
        this.products = allProducts.filter(function (product) {
          let filtered = true;

          filtered = product.category.id == catSearch;

          return filtered;
        });
      } else {
        this.products = allProducts.filter(function (product) {
          let filtered = true;
          if (catId != "") {
            filtered = product.category.id == catId;
          }
          if (filtered) {
            if (brId != "") {
              filtered = product.brand.id == brId;
            }
          }
          if (filtered) {
            if (mdId != "") {
              filtered = product.model && product.model.id == mdId;
            }
          }
          if (filtered) {
            if (yrId != "") {
              filtered = product.features.makeYear == yrId;
            }
          }   
          return filtered;
        });
      }

      if (this.productVisible > this.filterProducts.length) return;
      this.productVisible = parseInt(this.productVisible);
      this.productVisible = this.productVisible + 3;
      this.filterProducts = this.products.slice(0, this.productVisible);
    },

    // Filter Model
    filterModels() {
      let catId = this.catSearchId;
      let brId = this.brandSearchId;
      this.modelSearchId = "";
      this.brandmodels = this.models
        .filter((e) => {
          let filtered = true;
          if (catId != "") {
            filtered = e.category && e.category.id == catId;
          }
          if (filtered) {
            if (brId != "") {
              filtered = e.brand && e.brand.id == brId;
            }
          }
          return filtered;
        })
        .map((item) => item)
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },

    //Category Filter
    categoryFilter(catid) {
      let allProducts = this.allprod;
      if (catid == "") {
        this.products = allProducts;
      } else {
        this.products = [];
        this.products = allProducts.filter((e) => {
          return e.category.id == catid;
        });
      }

      this.catfilterId = catid;
      this.productVisible = 6;
      this.filterProducts = this.products.slice(0, this.productVisible);

      if (this.filterProducts.length > 0) {
        this.loadbtn = 1;
      }

      this.active_el = catid;

      var element = this.$el.getElementsByClassName("VehicleSearchResult")[0];
      var headerOffset = 109;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      this.yearSearchId = "";
      this.catSearchId = "";
      this.brandSearchId = "";
      this.modelSearchId = "";

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },

    //Model Brand Function
    onBrandChange(event) {
      if (event.target.value != "") {
        this.brandmodels = this.models
          .filter((e) => {
            return e.brand && e.brand.id == event.target.value;
          })
          .sort((a, b) => a.SortOrder - b.SortOrder);
      }
    },

    // Advance Search
    advanceSearch: function () {
      let catId = this.catSearchId;
      let brId = this.brandSearchId;
      let mdId = this.modelSearchId;
      let yrId = this.yearSearchId;
      this.loagingStatus = true;
      this.filterProducts = [];
      this.products = [];
      // this.$strapi.find('products',  {_limit: -1} )
      //  .then((res)=>{
      let allProducts = this.allprod;
      // this.allprod = this.allprod;
      this.loagingStatus = false;
      this.products = allProducts.filter(function (product) {
        let filtered = true;
        if (catId != "") {
          filtered = product.category.id == catId;
        }
        if (filtered) {
          if (brId != "") {
            filtered = product.brand.id == brId;
          }
        }
        if (filtered) {
          if (mdId != "") {
            filtered = product.model && product.model.id == mdId;
          }
        }
        if (filtered) {
          if (yrId != "") {
            filtered = product.features.makeYear == yrId;
          }
        }
        return filtered;
      });

      this.productVisible = 6;

      this.filterProducts = this.products.slice(0, this.productVisible);

      if (this.filterProducts.length > 0) {
        this.loadbtn = 1;
      }

      var element = this.$el.getElementsByClassName("VehicleSearchResult")[0];
      var headerOffset = 109;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      //  })
    },

    // Clear Advanced Search
    clearAdvanceSearch: function () {
      this.yearSearchId = "";
      this.catSearchId = "";
      this.brandSearchId = "";
      this.modelSearchId = "";
    },
  },
  head() {
    //Fetching Full Seo Details
    const fullSeo = {
      metaTitle: this.home.seo ? this.home.seo.metaTitle : "",
      metaDescription: this.home.seo ? this.home.seo.metaDescription : "",
      metaKeywords: this.home.seo ? this.home.seo.metaKeywords : "",
      shareImage: this.home.seo ? this.home.seo.shareImage.image : "",
    };

    return {
      title: fullSeo.metaTitle,
      meta: getMetaTags(fullSeo),
      link: [
        {
          rel: "canonical",
          href: process.env.API_URL + this.$route.fullPath,
        },
      ],
    };
  },
};
